export enum EPanels {
  PLACES = 'places',
  LAYERS = 'layers',
  CHAT = 'chat',
}

export enum APanels {
  EDIT = 'edit',
  PREVIEW = 'preview',
}
